/*
 * Base
 */

html {
    /* @link https://utopia.fyi/type/calculator?c=320,15,1.125,900,19,1.125,0,0,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */
    font-size: 0.9375em;
    font-size: clamp(0.9375em, 0.7996em + 0.6897vw, 1.1875em);
}

@screen sm {
    html {
        font-size: 1em;
    }
}
@screen md {
    html {
        /* @link        https://utopia.fyi/type/calculator/?c=1188,16,1.125,1920,23,1.125,0,0,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */
        font-size: 1.1em;
        font-size: clamp(1em, 0.29em + 0.9563vw, 1.4375em);
    }
}

body {
    @apply font-prose;
}

h1,
h2,
h3,
h4,
h5 {
    @apply font-title;
}

/* Links */
@layer base {
    a:hover {
        @apply underline;
    }
}
.link-style {
    @apply text-inherit
        underline underline-offset-4
        decoration-2 decoration-dotted decoration-grey-600
        hover:decoration-orange-500 hover:decoration-solid;
}
a.block-link {
    &:hover {
        @apply no-underline;
    }
}

/* Text Styles */
@layer components {
    .typo {
        &-h1 {
            @apply text-4xl leading-snug font-title font-bold;
        }
        &-h2 {
            @apply text-3xl leading-snug font-title font-bold;
        }
        &-h3 {
            @apply text-2xl leading-snug font-title font-bold;
        }
        &-h4 {
            @apply text-xl leading-snug font-title font-bold;
        }
        &-h5 {
            @apply text-lg leading-snug font-title font-bold;
        }
        &-h6 {
            @apply text-base leading-snug font-title font-bold;
        }
        &-p1 {
            @apply text-lg leading-normal font-prose;
        }
        &-p2 {
            @apply text-base leading-normal font-prose;
        }
        &-c1 {
            @apply text-lg leading-snug font-caption;
        }
        &-c2 {
            @apply text-base leading-normal font-caption tracking-wide;
        }
        &-c3 {
            @apply text-sm leading-snug font-caption;
        }
        &-c3-uppercase {
            @apply text-sm leading-snug font-caption uppercase tracking-wider;
        }
    }
}
@layer utilities {
    .small-caps {
        font-variant: small-caps;
    }
}

/* Frills */
.quo {
    margin-left: -0.24em;
}
.dquo {
    margin-left: -0.44em;
}
.tquo {
    margin-left: -0.68em;
}

/* Rich Text */
.richtext {
    p,
    ul,
    ol {
        @apply mt-0 mb-4;
    }
    & > p:last-child,
    & > ul:last-child,
    & > ol:last-child {
        @apply mb-0;
    }
    & + & {
        @apply mt-4;
    }

    h2 {
        @apply typo-h3 mb-4 mt-10;
    }
    h3 {
        @apply typo-h4 mb-4 mt-10;
    }
    h4 {
        @apply typo-h5 mb-4 mt-10;
    }
    h2:first-child,
    h3:first-child,
    h4:first-child {
        @apply mt-0;
    }

    ol,
    ul {
        @apply pl-4;
    }
    li li {
        @apply ml-2;
    }
    ol {
        @apply pl-6 list-decimal;
    }
    ul {
        list-style-type: '•';
    }
    ul li {
        padding-left: 0.5em;
    }
    li ul {
        list-style-type: circle;
    }
    ul ul,
    ol ol {
        @apply my-5 pl-20 list-decimal;
    }
    ol ol {
        @apply list-decimal;
    }
    ol ol ol {
        list-style-type: lower-roman;
    }
    a {
        @apply link-style;
    }
    a[href^='#fn-'] {
        @apply text-orange-500
            underline underline-offset-4
            decoration-auto decoration-dotted decoration-grey-600
            hover:decoration-orange-500 hover:decoration-solid;
    }
    a.no-underline {
        text-decoration: none;
    }

    strong {
        @apply font-bold;
    }
}

.large-richtext .richtext {
    p,
    ul,
    ol {
        @apply xs:typo-p1;
    }
    ol ol {
        @apply typo-p2;
    }
}

.counter-footnotes {
    counter-reset: footnotes;
    & li::before {
        counter-increment: footnotes;
        content: counter(footnotes);
        width: 1.84em;
        height: 1.84em;
        font-size: 0.8125em;
        @apply flex items-center justify-center shrink-0 font-bold text-center bg-orange-100 rounded-full;
    }
}
