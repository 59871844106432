/*
 * Buttons
*/

.button {
    --button-color: theme(colors.grey.500);
    &:hover,
    &:focus {
        @apply no-underline;
        --button-color: theme(colors.grey.700);
    }
}

.button-primary {
    color: var(--button-color);
    @apply inline-flex border typo-c3-uppercase;
    .label {
        @apply px-2 py-1.5;
    }
    .icon {
        background-color: var(--button-color);
        @apply flex h-7 w-7 items-center justify-center text-white;
        svg {
            @apply h-2.5 w-auto;
        }
    }
}

.button-primary-small {
    @apply button-primary;
    .label {
        @apply px-2 py-1 leading-none;
    }
    .icon {
        height: auto;
        width: 1.375rem;
    }
}
