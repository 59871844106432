/* Base */
html {
    scroll-behavior: smooth;
}

/* Defaults */
body {
    @apply bg-grey-500 antialiased;
}
p {
    @apply mt-0;
}

/* lazysizes auto width calculation */
picture img[data-sizes='auto'] {
    @apply block w-full;
}
picture img.w-auto[data-sizes='auto'] {
    width: auto;
}

/* Hide AlpineJS components until Alpine is ready */
html:not(.has-no-js) [x-cloak] {
    display: none;
}
