/* Base */
html {
    scroll-behavior: smooth;
}
/* Defaults */
body {
    @apply bg-grey-500 antialiased;
}
p {
    @apply mt-0;
}
/* lazysizes auto width calculation */
picture img[data-sizes='auto'] {
    @apply block w-full;
}
picture img.w-auto[data-sizes='auto'] {
    width: auto;
}
/* Hide AlpineJS components until Alpine is ready */
html:not(.has-no-js) [x-cloak] {
    display: none;
}
@tailwind base;
/*
 * Base
 */
html {
    /* @link https://utopia.fyi/type/calculator?c=320,15,1.125,900,19,1.125,0,0,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */
    font-size: 0.9375em;
    font-size: clamp(0.9375em, 0.7996em + 0.6897vw, 1.1875em);
}
@screen sm {
    html {
        font-size: 1em;
    }
}
@screen md {
    html {
        /* @link        https://utopia.fyi/type/calculator/?c=1188,16,1.125,1920,23,1.125,0,0,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */
        font-size: 1.1em;
        font-size: clamp(1em, 0.29em + 0.9563vw, 1.4375em);
    }
}
body {
    @apply font-prose;
}
h1,
h2,
h3,
h4,
h5 {
    @apply font-title;
}
/* Links */
@layer base {
    a:hover {
        @apply underline;
    }
}
.link-style {
    @apply text-inherit
        underline underline-offset-4
        decoration-2 decoration-dotted decoration-grey-600
        hover:decoration-orange-500 hover:decoration-solid;
}
a.block-link:hover {
        @apply no-underline;
    }
/* Text Styles */
@layer components {
        .typo-h1 {
            @apply text-4xl leading-snug font-title font-bold;
        }
        .typo-h2 {
            @apply text-3xl leading-snug font-title font-bold;
        }
        .typo-h3 {
            @apply text-2xl leading-snug font-title font-bold;
        }
        .typo-h4 {
            @apply text-xl leading-snug font-title font-bold;
        }
        .typo-h5 {
            @apply text-lg leading-snug font-title font-bold;
        }
        .typo-h6 {
            @apply text-base leading-snug font-title font-bold;
        }
        .typo-p1 {
            @apply text-lg leading-normal font-prose;
        }
        .typo-p2 {
            @apply text-base leading-normal font-prose;
        }
        .typo-c1 {
            @apply text-lg leading-snug font-caption;
        }
        .typo-c2 {
            @apply text-base leading-normal font-caption tracking-wide;
        }
        .typo-c3 {
            @apply text-sm leading-snug font-caption;
        }
        .typo-c3-uppercase {
            @apply text-sm leading-snug font-caption uppercase tracking-wider;
        }
}
@layer utilities {
    .small-caps {
        font-variant: small-caps;
    }
}
/* Frills */
.quo {
    margin-left: -0.24em;
}
.dquo {
    margin-left: -0.44em;
}
.tquo {
    margin-left: -0.68em;
}
/* Rich Text */
.richtext p,
    .richtext ul,
    .richtext ol {
        @apply mt-0 mb-4;
    }
.richtext > p:last-child,
    .richtext > ul:last-child,
    .richtext > ol:last-child {
        @apply mb-0;
    }
.richtext + .richtext {
        @apply mt-4;
    }
.richtext h2 {
        @apply typo-h3 mb-4 mt-10;
    }
.richtext h3 {
        @apply typo-h4 mb-4 mt-10;
    }
.richtext h4 {
        @apply typo-h5 mb-4 mt-10;
    }
.richtext h2:first-child,
    .richtext h3:first-child,
    .richtext h4:first-child {
        @apply mt-0;
    }
.richtext ol,
    .richtext ul {
        @apply pl-4;
    }
.richtext li li {
        @apply ml-2;
    }
.richtext ol {
        @apply pl-6 list-decimal;
    }
.richtext ul {
        list-style-type: '•';
    }
.richtext ul li {
        padding-left: 0.5em;
    }
.richtext li ul {
        list-style-type: circle;
    }
.richtext ul ul,
    .richtext ol ol {
        @apply my-5 pl-20 list-decimal;
    }
.richtext ol ol {
        @apply list-decimal;
    }
.richtext ol ol ol {
        list-style-type: lower-roman;
    }
.richtext a {
        @apply link-style;
    }
.richtext a[href^='#fn-'] {
        @apply text-orange-500
            underline underline-offset-4
            decoration-auto decoration-dotted decoration-grey-600
            hover:decoration-orange-500 hover:decoration-solid;
    }
.richtext a.no-underline {
        text-decoration: none;
    }
.richtext strong {
        @apply font-bold;
    }
.large-richtext .richtext p,
    .large-richtext .richtext ul,
    .large-richtext .richtext ol {
        @apply xs:typo-p1;
    }
.large-richtext .richtext ol ol {
        @apply typo-p2;
    }
.counter-footnotes {
    counter-reset: footnotes;
}
.counter-footnotes li::before {
        counter-increment: footnotes;
        content: counter(footnotes);
        width: 1.84em;
        height: 1.84em;
        font-size: 0.8125em;
        @apply flex items-center justify-center shrink-0 font-bold text-center bg-orange-100 rounded-full;
    }
/*
 * Buttons
*/
.button {
    --button-color: theme(colors.grey.500);
}
.button:hover,
    .button:focus {
        @apply no-underline;
        --button-color: theme(colors.grey.700);
    }
.button-primary {
    color: var(--button-color);
    @apply inline-flex border typo-c3-uppercase;
}
.button-primary .label {
        @apply px-2 py-1.5;
    }
.button-primary .icon {
        background-color: var(--button-color);
        @apply flex h-7 w-7 items-center justify-center text-white;
    }
.button-primary .icon svg {
            @apply h-2.5 w-auto;
        }
.button-primary-small {
    @apply button-primary;
}
.button-primary-small .label {
        @apply px-2 py-1 leading-none;
    }
.button-primary-small .icon {
        height: auto;
        width: 1.375rem;
    }
@tailwind components;
/* Custom Components */
/* Fluid embed iframes */
.responsive-embed {
    position: relative;
}
.responsive-embed iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
/* Lazyloading images etc. */
.lazyload,
.lazyloading {
    transition: all 0.3s;
    opacity: 0.3;
    background: transparent no-repeat scroll center center;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='38' height='38' xmlns='http://www.w3.org/2000/svg' stroke='%23CCCCCC'%3E%3Cg transform='translate(1 1)' stroke-width='2' fill='none' fill-rule='evenodd'%3E%3Ccircle stroke-opacity='.5' cx='18' cy='18' r='18'/%3E%3Cpath d='M36 18A18 18 0 0 0 18 0'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite'/%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    background-size: auto 25%;
}
.lazyloaded {
    transition: all 0.3s;
    opacity: 1;
    background-image: none;
}
/* Tetris compount backgrounds */
@layer components {
    .bg-tetris-1h,
    .bg-tetris-2h {
        background-image: url(../assets/img/tetris-1h.png);
        background-size: auto 0.875rem;
        background-repeat: repeat-x;
        background-position: center 1.25rem;
    }
    .bg-tetris-2h {
        background-image: url(../assets/img/tetris-2h.png);
    }
    .bg-tetris-1v,
    .bg-tetris-2v {
        background-image: url(../assets/img/tetris-1v.png);
        background-size: 0.875rem auto;
        background-repeat: repeat-y;
        background-position: right 1.25rem center;
    }
    .bg-tetris-2v {
        background-image: url(../assets/img/tetris-2v.png);
    }
}
/*
    Grids and Layouts
*/
:root {
    --grid-columns: 6;
    --grid-gap: 1.25rem;
    --grid-margin-right: 1.25rem;
    --grid-margin-left: 1.25rem;
    --grid-container: 100%;

    --grid-bleed-left: calc(var(--grid-margin-left) - var(--grid-gap));
    --grid-bleed-right: calc(var(--grid-margin-right) - var(--grid-gap));
}
@screen xs {
    :root {
        --grid-container: 700px;
    }
}
@screen sm {
    :root {
        --grid-margin-right: 4rem;
        --grid-margin-left: 4rem;
        --grid-columns: 12;
        --grid-container: 58.75rem;
    }
}
/* Areas */
@layer utilities {
    .area-full {
        grid-column-start: 1;
        grid-column-end: -1;
    }
    .area-base,
    .area-core,
    .area-core-stretch-end,
    .area-editorial,
    .area-editorialAside {
        grid-column-start: 2;
        grid-column-end: -2;
    }
    .area-coreAside {
        grid-column-start: 6;
        grid-column-end: -2;
    }
    .area-halfA {
        grid-column-start: 2;
        grid-column-end: 5;
    }
    .area-halfB {
        grid-column-start: 5;
        grid-column-end: 8;
    }
    .area-five\/sixA,
    .area-five\/sixB {
        grid-column-start: 2;
        grid-column-end: -2;
    }
    .area-thirdA {
        grid-column-start: 2;
        grid-column-end: 4;
    }
    .area-thirdB {
        grid-column-start: 4;
        grid-column-end: 6;
    }
    .area-thirdC {
        grid-column-start: 6;
        grid-column-end: 8;
    }
    .area-thirdAB {
        grid-column-start: 2;
        grid-column-end: 6;
    }
    .area-thirdBC {
        grid-column-start: 4;
        grid-column-end: 8;
    }

    @screen sm {
        .area-core {
            grid-column-start: 2;
            grid-column-end: 9;
        }
        .area-core-stretch-end {
            grid-column-start: 2;
            grid-column-end: 10;
        }
        .area-coreAside {
            grid-column-start: 10;
            grid-column-end: -2;
        }
        .area-halfA {
            grid-column-start: 2;
            grid-column-end: 8;
        }
        .area-halfB {
            grid-column-start: 8;
            grid-column-end: -2;
        }
        .area-five\/sixA {
            grid-column-start: 2;
            grid-column-end: 7;
        }
        .area-five\/sixB {
            grid-column-start: 7;
            grid-column-end: -2;
        }
        .area-thirdA {
            grid-column-start: 2;
            grid-column-end: 6;
        }
        .area-thirdB {
            grid-column-start: 6;
            grid-column-end: 10;
        }
        .area-thirdC {
            grid-column-start: 10;
            grid-column-end: 14;
        }
        .area-thirdAB {
            grid-column-start: 2;
            grid-column-end: 10;
        }
        .area-thirdBC {
            grid-column-start: 6;
            grid-column-end: 14;
        }
        .area-editorial {
            grid-column-start: 6;
            grid-column-end: -2;
        }
        .area-editorialAside {
            grid-column-start: 2;
            grid-column-end: 5;
        }
    }
}
/* Layout utilities */
@layer utilities {
    .container {
        max-width: theme(screens.lg);
    }
    .container-grid-full {
        --grid-container: 100%;
    }

    .layout-gap {
        gap: 1.25rem;
        gap: var(--grid-gap);
    }
    .layout-gap-x {
        column-gap: 1.25rem;
        column-gap: var(--grid-gap);
    }
    .layout-gap-y {
        row-gap: 1.25rem;
        row-gap: var(--grid-gap);
    }
}
.layout-base {
    --grid-column-width: calc((var(--grid-container) - (var(--grid-columns) - 1)*var(--grid-gap))/var(--grid-columns));
    --grid-column-width: calc(
        (var(--grid-container) - (var(--grid-columns) - 1) * var(--grid-gap)) /
            var(--grid-columns)
    );

    display: grid;
    grid-template-columns:
        minmax(0rem, 1fr)
        repeat(6, minmax(0, var(--grid-column-width)))
        minmax(0rem, 1fr);
    grid-template-columns:
        minmax(calc(1.25rem - 1.25rem), 1fr)
        repeat(6, minmax(0, var(--grid-column-width)))
        minmax(calc(1.25rem - 1.25rem), 1fr);
    grid-template-columns:
        minmax(var(--grid-bleed-left), 1fr)
        repeat(var(--grid-columns), minmax(0, var(--grid-column-width)))
        minmax(var(--grid-bleed-right), 1fr);
    column-gap: 1.25rem;
    column-gap: var(--grid-gap);
}
@tailwind utilities;
/* Additional Utilities */
/* Force an aspect ratio on an element */
.aspect-box:before {
    content: '';
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
}
.aspect-box:after {
    content: '';
    display: table;
    clear: both;
}
.aspect-ratio-1-1:before {
    padding-top: 100%;
}
.aspect-ratio-16-9:before {
    padding-top: 56.25%;
}
.aspect-ratio-3-2:before {
    padding-top: 66.67%;
}
.bg-stripes-header {
    background-image: url(../assets/img/header-stripes.png);
}
.bg-stripes-footer {
    background-image: url(../assets/img/footer-stripes.png);
}
.backface-hidden {
    backface-visibility: hidden;
}
/*! PhotoSwipe main CSS by Dmytro Semenov | photoswipe.com */
.pswp {
  --pswp-bg: #000;
  --pswp-placeholder-bg: #222;
  

  --pswp-root-z-index: 100000;
  
  --pswp-preloader-color: rgba(79, 79, 79, 0.4);
  --pswp-preloader-color-secondary: rgba(255, 255, 255, 0.9);
  
  /* defined via js:
  --pswp-transition-duration: 333ms; */
  
  --pswp-icon-color: #fff;
  --pswp-icon-color-secondary: #4f4f4f;
  --pswp-icon-stroke-color: #4f4f4f;
  --pswp-icon-stroke-width: 2px;

  --pswp-error-text-color: var(--pswp-icon-color);
}
/*
	Styles for basic PhotoSwipe (pswp) functionality (sliding area, open/close transitions)
*/
.pswp {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: var(--pswp-root-z-index);
	display: none;
	touch-action: none;
	outline: 0;
	opacity: 0.003;
	contain: layout style size;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
/* Prevents focus outline on the root element,
  (it may be focused initially) */
.pswp:focus {
  outline: 0;
}
.pswp * {
  box-sizing: border-box;
}
.pswp img {
  max-width: none;
}
.pswp--open {
	display: block;
}
.pswp,
.pswp__bg {
	transform: translateZ(0);
	will-change: opacity;
}
.pswp__bg {
  opacity: 0.005;
	background: var(--pswp-bg);
}
.pswp,
.pswp__scroll-wrap {
	overflow: hidden;
}
.pswp__scroll-wrap,
.pswp__bg,
.pswp__container,
.pswp__item,
.pswp__content,
.pswp__img,
.pswp__zoom-wrap {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.pswp__img,
.pswp__zoom-wrap {
	width: auto;
	height: auto;
}
.pswp--click-to-zoom.pswp--zoom-allowed .pswp__img {
	cursor: -webkit-zoom-in;
	cursor: -moz-zoom-in;
	cursor: zoom-in;
}
.pswp--click-to-zoom.pswp--zoomed-in .pswp__img {
	cursor: move;
	cursor: -webkit-grab;
	cursor: -moz-grab;
	cursor: grab;
}
.pswp--click-to-zoom.pswp--zoomed-in .pswp__img:active {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}
/* :active to override grabbing cursor */
.pswp--no-mouse-drag.pswp--zoomed-in .pswp__img,
.pswp--no-mouse-drag.pswp--zoomed-in .pswp__img:active,
.pswp__img {
	cursor: -webkit-zoom-out;
	cursor: -moz-zoom-out;
	cursor: zoom-out;
}
/* Prevent selection and tap highlights */
.pswp__container,
.pswp__img,
.pswp__button,
.pswp__counter {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.pswp__item {
	/* z-index for fade transition */
	z-index: 1;
	overflow: hidden;
}
.pswp__hidden {
	display: none !important;
}
/* Allow to click through pswp__content element, but not its children */
.pswp__content {
  pointer-events: none;
}
.pswp__content > * {
  pointer-events: auto;
}
/*

  PhotoSwipe UI

*/
/*
	Error message appears when image is not loaded
	(JS option errorMsg controls markup)
*/
.pswp__error-msg-container {
  display: grid;
}
.pswp__error-msg {
	margin: auto;
	font-size: 1em;
	line-height: 1;
	color: var(--pswp-error-text-color);
}
/*
class pswp__hide-on-close is applied to elements that
should hide (for example fade out) when PhotoSwipe is closed
and show (for example fade in) when PhotoSwipe is opened
 */
.pswp .pswp__hide-on-close {
	opacity: 0.005;
	will-change: opacity;
	transition: opacity var(--pswp-transition-duration) cubic-bezier(0.4, 0, 0.22, 1);
	z-index: 10; /* always overlap slide content */
	pointer-events: none; /* hidden elements should not be clickable */
}
/* class pswp--ui-visible is added when opening or closing transition starts */
.pswp--ui-visible .pswp__hide-on-close {
	opacity: 1;
	pointer-events: auto;
}
/* <button> styles, including css reset */
.pswp__button {
	position: relative;
	display: block;
	width: 50px;
	height: 60px;
	padding: 0;
	margin: 0;
	overflow: hidden;
	cursor: pointer;
	background: none;
	border: 0;
	box-shadow: none;
	opacity: 0.85;
	-webkit-appearance: none;
	-webkit-touch-callout: none;
}
.pswp__button:hover,
.pswp__button:active,
.pswp__button:focus {
  transition: none;
  padding: 0;
  background: none;
  border: 0;
  box-shadow: none;
  opacity: 1;
}
.pswp__button:disabled {
  opacity: 0.3;
  cursor: auto;
}
.pswp__icn {
  fill: var(--pswp-icon-color);
  color: var(--pswp-icon-color-secondary);
}
.pswp__icn {
  position: absolute;
  top: 14px;
  left: 9px;
  width: 32px;
  height: 32px;
  overflow: hidden;
  pointer-events: none;
}
.pswp__icn-shadow {
  stroke: var(--pswp-icon-stroke-color);
  stroke-width: var(--pswp-icon-stroke-width);
  fill: none;
}
.pswp__icn:focus {
	outline: 0;
}
/*
	div element that matches size of large image,
	large image loads on top of it,
	used when msrc is not provided
*/
div.pswp__img--placeholder,
.pswp__img--with-bg {
	background: var(--pswp-placeholder-bg);
}
.pswp__top-bar {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 60px;
	display: flex;
  flex-direction: row;
  justify-content: flex-end;
	z-index: 10;

	/* allow events to pass through top bar itself */
	pointer-events: none !important;
}
.pswp__top-bar > * {
  pointer-events: auto;
  /* this makes transition significantly more smooth,
     even though inner elements are not animated */
  will-change: opacity;
}
/*

  Close button

*/
.pswp__button--close {
  margin-right: 6px;
}
/*

  Arrow buttons

*/
.pswp__button--arrow {
  position: absolute;
  top: 0;
  width: 75px;
  height: 100px;
  top: 50%;
  margin-top: -50px;
}
.pswp__button--arrow:disabled {
  display: none;
  cursor: default;
}
.pswp__button--arrow .pswp__icn {
  top: 50%;
  margin-top: -30px;
  width: 60px;
  height: 60px;
  background: none;
  border-radius: 0;
}
.pswp--one-slide .pswp__button--arrow {
  display: none;
}
/* hide arrows on touch screens */
.pswp--touch .pswp__button--arrow {
  visibility: hidden;
}
/* show arrows only after mouse was used */
.pswp--has_mouse .pswp__button--arrow {
  visibility: visible;
}
.pswp__button--arrow--prev {
  right: auto;
  left: 0px;
}
.pswp__button--arrow--next {
  right: 0px;
}
.pswp__button--arrow--next .pswp__icn {
  left: auto;
  right: 14px;
  /* flip horizontally */
  transform: scale(-1, 1);
}
/*

  Zoom button

*/
.pswp__button--zoom {
  display: none;
}
.pswp--zoom-allowed .pswp__button--zoom {
  display: block;
}
/* "+" => "-" */
.pswp--zoomed-in .pswp__zoom-icn-bar-v {
  display: none;
}
/*

  Loading indicator

*/
.pswp__preloader {
  position: relative;
  overflow: hidden;
  width: 50px;
  height: 60px;
  margin-right: auto;
}
.pswp__preloader .pswp__icn {
  opacity: 0;
  transition: opacity 0.2s linear;
  animation: pswp-clockwise 600ms linear infinite;
}
.pswp__preloader--active .pswp__icn {
  opacity: 0.85;
}
@keyframes pswp-clockwise {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/*

  "1 of 10" counter

*/
.pswp__counter {
  height: 30px;
  margin-top: 15px;
  margin-inline-start: 20px;
  font-size: 14px;
  line-height: 30px;
  color: var(--pswp-icon-color);
  text-shadow: 1px 1px 3px var(--pswp-icon-color-secondary);
  opacity: 0.85;
}
.pswp--one-slide .pswp__counter {
  display: none;
}
