/*
    Grids and Layouts
*/
:root {
    --grid-columns: 6;
    --grid-gap: 1.25rem;
    --grid-margin-right: 1.25rem;
    --grid-margin-left: 1.25rem;
    --grid-container: 100%;

    --grid-bleed-left: calc(var(--grid-margin-left) - var(--grid-gap));
    --grid-bleed-right: calc(var(--grid-margin-right) - var(--grid-gap));
}

@screen xs {
    :root {
        --grid-container: 700px;
    }
}

@screen sm {
    :root {
        --grid-margin-right: 4rem;
        --grid-margin-left: 4rem;
        --grid-columns: 12;
        --grid-container: 58.75rem;
    }
}

/* Areas */
@layer utilities {
    .area-full {
        grid-column-start: 1;
        grid-column-end: -1;
    }
    .area-base,
    .area-core,
    .area-core-stretch-end,
    .area-editorial,
    .area-editorialAside {
        grid-column-start: 2;
        grid-column-end: -2;
    }
    .area-coreAside {
        grid-column-start: 6;
        grid-column-end: -2;
    }
    .area-halfA {
        grid-column-start: 2;
        grid-column-end: 5;
    }
    .area-halfB {
        grid-column-start: 5;
        grid-column-end: 8;
    }
    .area-five\/sixA,
    .area-five\/sixB {
        grid-column-start: 2;
        grid-column-end: -2;
    }
    .area-thirdA {
        grid-column-start: 2;
        grid-column-end: 4;
    }
    .area-thirdB {
        grid-column-start: 4;
        grid-column-end: 6;
    }
    .area-thirdC {
        grid-column-start: 6;
        grid-column-end: 8;
    }
    .area-thirdAB {
        grid-column-start: 2;
        grid-column-end: 6;
    }
    .area-thirdBC {
        grid-column-start: 4;
        grid-column-end: 8;
    }

    @screen sm {
        .area-core {
            grid-column-start: 2;
            grid-column-end: 9;
        }
        .area-core-stretch-end {
            grid-column-start: 2;
            grid-column-end: 10;
        }
        .area-coreAside {
            grid-column-start: 10;
            grid-column-end: -2;
        }
        .area-halfA {
            grid-column-start: 2;
            grid-column-end: 8;
        }
        .area-halfB {
            grid-column-start: 8;
            grid-column-end: -2;
        }
        .area-five\/sixA {
            grid-column-start: 2;
            grid-column-end: 7;
        }
        .area-five\/sixB {
            grid-column-start: 7;
            grid-column-end: -2;
        }
        .area-thirdA {
            grid-column-start: 2;
            grid-column-end: 6;
        }
        .area-thirdB {
            grid-column-start: 6;
            grid-column-end: 10;
        }
        .area-thirdC {
            grid-column-start: 10;
            grid-column-end: 14;
        }
        .area-thirdAB {
            grid-column-start: 2;
            grid-column-end: 10;
        }
        .area-thirdBC {
            grid-column-start: 6;
            grid-column-end: 14;
        }
        .area-editorial {
            grid-column-start: 6;
            grid-column-end: -2;
        }
        .area-editorialAside {
            grid-column-start: 2;
            grid-column-end: 5;
        }
    }
}

/* Layout utilities */
@layer utilities {
    .container {
        max-width: theme(screens.lg);
    }
    .container-grid-full {
        --grid-container: 100%;
    }

    .layout-gap {
        gap: var(--grid-gap);
    }
    .layout-gap-x {
        column-gap: var(--grid-gap);
    }
    .layout-gap-y {
        row-gap: var(--grid-gap);
    }
}

.layout-base {
    --grid-column-width: calc(
        (var(--grid-container) - (var(--grid-columns) - 1) * var(--grid-gap)) /
            var(--grid-columns)
    );

    display: grid;
    grid-template-columns:
        minmax(var(--grid-bleed-left), 1fr)
        repeat(var(--grid-columns), minmax(0, var(--grid-column-width)))
        minmax(var(--grid-bleed-right), 1fr);
    column-gap: var(--grid-gap);
}
