/* Custom Components */

/* Fluid embed iframes */
.responsive-embed {
    position: relative;
    iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}

/* Lazyloading images etc. */
.lazyload,
.lazyloading {
    transition: all 0.3s;
    opacity: 0.3;
    background: transparent no-repeat scroll center center;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='38' height='38' xmlns='http://www.w3.org/2000/svg' stroke='%23CCCCCC'%3E%3Cg transform='translate(1 1)' stroke-width='2' fill='none' fill-rule='evenodd'%3E%3Ccircle stroke-opacity='.5' cx='18' cy='18' r='18'/%3E%3Cpath d='M36 18A18 18 0 0 0 18 0'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='0.9s' repeatCount='indefinite'/%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    background-size: auto 25%;
}
.lazyloaded {
    transition: all 0.3s;
    opacity: 1;
    background-image: none;
}

/* Tetris compount backgrounds */
@layer components {
    .bg-tetris-1h,
    .bg-tetris-2h {
        background-image: url(../assets/img/tetris-1h.png);
        background-size: auto 0.875rem;
        background-repeat: repeat-x;
        background-position: center 1.25rem;
    }
    .bg-tetris-2h {
        background-image: url(../assets/img/tetris-2h.png);
    }
    .bg-tetris-1v,
    .bg-tetris-2v {
        background-image: url(../assets/img/tetris-1v.png);
        background-size: 0.875rem auto;
        background-repeat: repeat-y;
        background-position: right 1.25rem center;
    }
    .bg-tetris-2v {
        background-image: url(../assets/img/tetris-2v.png);
    }
}
